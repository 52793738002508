<template>
  <a-modal
    title="新建信用证还款"
    :width="1380"
    :visible="visible"
    :confirmLoading="loading"
    @ok="this.ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="项目" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'project_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入项目"
                :default-active-first-option="false"
                @search="this.projectSearch"
                @change="handleProjectChange"
              >
                <a-select-option v-for="d in projectList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="开证流程编号">
              <a-select
                style="width: 80%"
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'credit_letter_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入信用证"
                :default-active-first-option="false"
                @search="this.creditLetterSearch"
                @change="handleCreditLetterChange"
              >
                <a-select-option v-for="d in creditLetterList" :key="d.id" :tt="d.number">
                  {{ d.number }}
                </a-select-option>
              </a-select>
              <span>
                <span style="color: rgba(0, 0, 0, 0.85); padding-left: 10px">信用证已还完：</span><a-switch v-decorator="['is_done', { initialValue: false, valuePropName: 'checked' }]" />
              </span>
            </a-form-item>
            <a-form-item label="信用证详情编号" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'credit_letter_detail_number',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入信用证详情编号"
                :default-active-first-option="false"
                @search="this.creditLetterDetailSearch"
                @change="handleCreditLetterDetailChange"
              >
                <a-select-option v-for="d in creditLetterDetailNumberList" :key="d.id" :tt="d.number">
                  {{ d.number }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="授信银行">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'bank_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入银行"
                :default-active-first-option="false"
                @search="this.bankSearch"
                @change="handleBankChange"
              >
                <a-select-option v-for="d in bankList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="银行信用证编号">
              <a-input v-decorator="['bank_credit_letter_number', {rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="银行信用证编号" />
            </a-form-item>
            <a-form-item label="信用证货币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="人民币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount_cny', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="信用证货币到人民币的汇率">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['to_cny_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="融资利率（%）" v-if="false">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['financing_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="总计费用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['total_amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="还款时间">
              <a-date-picker
                v-decorator="['repayment_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="还款时间"
                value-format="yyyy-MM-DD"
              />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="false">
            <a-form-item label="还证详情">
              <a-button icon="plus" @click="handleAddDetail">新建</a-button>
              <a-table
                key="key"
                bordered
                :data-source="otherData.detail"
                :columns="detailColumns"
                v-if="this.otherData.detail.length > 0"
                :scroll="{ x: 1000}"
                :pagination="false">
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 200px">
                  {{ (text / 1).toFixed(2) | numberFormat }}
                </div>
                <div slot="rate_render" slot-scope="text" style="text-align: center; min-width: 90px; max-width: 100px">
                  {{ text }}%
                </div>
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                    v-if="otherData.detail.length"
                    title="确定删除?"
                    @confirm="() => onDetailDelete(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="附件">
              <a-button icon="plus" @click="handleAddAttachment">新建</a-button>
              <a-table
                bordered
                :data-source="otherData.attachment"
                :columns="attachmentColumns"
                v-if="this.otherData.attachment.length > 0"
                :pagination="false"
                key="key">
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                    v-if="otherData.attachment.length"
                    title="确定删除?"
                    @confirm="() => onAttachmentDelete(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
            <a-form-item label="是否押汇">
              <a-switch v-decorator="['is_bill_negotiation', { initialValue: false, valuePropName: 'checked' }]" @change="openBillNegotiation"/>
            </a-form-item>
            <a-form-item label="押汇额度占用" v-if="isOpenBillNegotiation">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['bill_negotiation_limit', {rules: [{required: isOpenBillNegotiation, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="开启审批">
              <a-switch v-decorator="['is_approval_needed', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <component
        :ref="currentComponent"
        @cancel="handleAddCancel"
        @ok="handleOk"
        :record="mdl"
        :is="currentComponent"
        :visible="visibleFrom"
        v-if="visibleFrom"
        :loading="confirmLoadingFrom"
        :model="mdl"></component>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { project_list } from '@/api/trade_project'
import { credit_letter_list } from '@/api/trade_credit_letter'
import DetailCreateForm from '../detail/modules/CreateForm'
import AttachmentCreateForm from '../attachment/modules/CreateForm'
import { credit_letter_detail_list } from '@/api/trade_credit_letter_detail'
import { bank_list } from '@/api/trade_bank'

// 表单字段
const fields = ['id', 'name', 'number', 'contact', 'phone', 'mail']
export default {
  components: {
    DetailCreateForm,
    AttachmentCreateForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.creditLetterSearch = debounce(this.handleCreditLetterSearch, 800)
    this.creditLetterDetailSearch = debounce(this.handleCreditLetterDetailSearch, 800)
    this.bankSearch = debounce(this.handleBankSearch, 800)
    return {
      form: this.$form.createForm(this),
      projectList: [],
      creditLetterList: [],
      creditLetterDetailNumberList: [],
      bankList: [],
      project_id: -1,
      currentComponent: '',
      visibleFrom: false,
      confirmLoadingFrom: false,
      isOpenBillNegotiation: false,
      mdl: {},
      otherData: {
        attachment: [],
        detail: []
      },
      attachmentColumns: [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '附件',
          dataIndex: 'url'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      detailColumns: [
        {
          title: '授信银行',
          dataIndex: 'bank_name',
          width: 150,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '信用证货币金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 180,
          ellipsis: true
        },
        {
          title: '人民币金额',
          dataIndex: 'amount_cny',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 160,
          ellipsis: true
        },
        {
          title: '信用证货币到人民币的汇率',
          dataIndex: 'to_cny_rate',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 260,
          ellipsis: true
        },
        {
          title: '总计费用',
          dataIndex: 'total_amount',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 150,
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150,
          ellipsis: true
        },
        {
          title: '还款日期',
          dataIndex: 'repayment_date',
          ellipsis: true,
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    console.log('custom modal created', this.model)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.model && this.form.setFieldsValue(pick(this.model, fields))
    this.project_id = this.$route.params.project_id
    this.handleProjectSearch(undefined)
    this.handleCreditLetterSearch(undefined)
    this.handleBankSearch(undefined)
  },
  methods: {
    moment,
    handleBankSearch (value) {
      bank_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.bankList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleBankChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleBankSearch(value)
      }
    },
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      } else {
        this.project_id = value
        this.creditLetterList = []
        this.handleCreditLetterSearch(undefined)
      }
    },
    handleCreditLetterSearch (value) {
      credit_letter_list({ number: value, project_id: this.project_id, is_done: false }).then((res) => {
        const result = res.data.entries || []
        this.creditLetterList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCreditLetterChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCreditLetterSearch(value)
      } else {
        // this.mdl.credit_letter_id = value
        this.handleCreditLetterDetailSearch(value)
      }
    },
    handleCreditLetterDetailSearch (value) {
      credit_letter_detail_list({ credit_letter_id: value }).then((res) => {
        const result = res.data.entries || []
        this.creditLetterDetailNumberList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCreditLetterDetailChange (value) {
      if (value === undefined) {
        this.form.setFieldsValue({
          bank_name: '',
          bank: ''
        })
        this.bank_id = -1
        this.handleCreditLetterDetailSearch(value)
      } else {
        this.form.setFieldsValue({
          bank_name: this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank,
          bank: '' + this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank_id
        })
        this.bank_id = this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank_id
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleAddAttachment () {
      this.currentComponent = 'AttachmentCreateForm'
      this.visibleFrom = true
    },
    handleAddDetail () {
      if (this.mdl.credit_letter_id === undefined) {
        this.$message.warning('选择信用证编号')
        return
      }
      this.currentComponent = 'DetailCreateForm'
      this.visibleFrom = true
    },
    onAttachmentDelete (key) {
      const dataSource = [...this.otherData.attachment]
      this.otherData.attachment = dataSource.filter(item => item.key !== key)
    },
    onDetailDelete (key) {
      const dataSource = [...this.otherData.detail]
      this.otherData.detail = dataSource.filter(item => item.key !== key)
    },
    handleAddCancel () {
      this.visibleFrom = false
      const form = this.$refs[this.currentComponent].form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk (date) {
      const form = this.$refs[this.currentComponent].form
      form.validateFields((errors, values) => {
        if (!errors) {
          // console.log('values', values, date)
          // 新增
          if (this.currentComponent === 'AttachmentCreateForm') {
            values.url = values.url.fileList[0].url
            const current_date = new Date()
            values.key = current_date.getHours() + '' + current_date.getMinutes() + '' + current_date.getSeconds()
            this.otherData.attachment.push(values)
          } else if (this.currentComponent === 'DetailCreateForm') {
            values = { ...values, ...date }
            // values.bank_id = values.bank.key
            // values.bank_name = values.bank.label
            // values.bank = undefined
            const current_date = new Date()
            values.key = current_date.getHours() + '' + current_date.getMinutes() + '' + current_date.getSeconds()
            console.log('values', values)
            this.otherData.detail.push(values)
          }
          this.visibleFrom = false
          form.resetFields()
        }
      })
    },
    ok () {
      if (this.otherData.detail) {
        this.otherData.detail = this.otherData.detail.map(item => {
          item.bank_name = undefined
          item.key = undefined
          return item
        })
      }
      if (this.otherData.attachment) {
        this.otherData.attachment = this.otherData.attachment.map(item => {
          item.key = undefined
          return item
        })
      }
      this.$emit('ok', this.otherData)
    },
    openBillNegotiation (value) {
      console.log(value)
      this.isOpenBillNegotiation = value
    }
  }
}
</script>
